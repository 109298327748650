@font-face {
    font-family: MontserratLight;
    src: url(../Montserrat/Montserrat-Light.ttf) format('truetype');
    font-weight: 300;
}
@font-face {
    font-family: MontserratBold;
    src: url(../Montserrat/Montserrat-Bold.ttf) format('truetype');
    font-weight: 700;
}
@font-face {
    font-family: MontserratRegular;
    src: url(../Montserrat/Montserrat-Regular.ttf) format('truetype');
    font-weight: 400;
}
@font-face {
	font-family: MontserratSemiBold;
	src: url(../Montserrat/Montserrat-SemiBold.ttf) format('truetype');
	font-weight: 600;
}
@font-face {
	font-family: MontserratExtraBold;
	src: url(../Montserrat/Montserrat-ExtraBold.ttf) format('truetype');
	font-weight: 800;
}
@font-face {
	font-family: MontserratExtraLight;
	src: url(../Montserrat/Montserrat-ExtraLight.ttf) format('truetype');
	font-weight: 200;
}