.navbar {
    height: calc(100vh - 20px);
    width: 78px;
    min-width: 78px;
    display: flex;
    gap: 40px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;
    border-radius: 0;
    gap: 10px;
}
.divider {
    width: 80%;
    height: 2px;
    border-radius: 2px;
    background: var(--md-sys-color-primary-elevate-4);
}
.navbar-items{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.navbar-item {
    color: var(--md-sys-color-on-background);
    cursor: pointer;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 99px;
    transition: all 100ms;
}
a {
    text-decoration: none;
}
.active {
    transition: all 100ms;
}
.navbar-item:hover {
    background: var(--md-sys-color-primary-elevate-3);
}
.active .navbar-item {
    background: var(--md-sys-color-primary-elevate-3);
    color: var(--md-sys-color-primary);
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.07), 0px 1px 3px rgba(0, 0, 0, 0.15);
}
.active .navbar-item:hover {
    background: var(--md-sys-color-primary-elevate-5);
}