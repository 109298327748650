:root {
 --md-sys-color-primary: var(--md-sys-color-primary-light);
 --md-sys-color-on-primary: var(--md-sys-color-on-primary-light);
 --md-sys-color-primary-container: var(--md-sys-color-primary-container-light);
 --md-sys-color-on-primary-container: var(--md-sys-color-on-primary-container-light);
 --md-sys-color-secondary: var(--md-sys-color-secondary-light);
 --md-sys-color-on-secondary: var(--md-sys-color-on-secondary-light);
 --md-sys-color-secondary-container: var(--md-sys-color-secondary-container-light);
 --md-sys-color-on-secondary-container: var(--md-sys-color-on-secondary-container-light);
 --md-sys-color-tertiary: var(--md-sys-color-tertiary-light);
 --md-sys-color-on-tertiary: var(--md-sys-color-on-tertiary-light);
 --md-sys-color-tertiary-container: var(--md-sys-color-tertiary-container-light);
 --md-sys-color-on-tertiary-container: var(--md-sys-color-on-tertiary-container-light);
 --md-sys-color-error: var(--md-sys-color-error-light);
 --md-sys-color-error-container: var(--md-sys-color-error-container-light);
 --md-sys-color-on-error: var(--md-sys-color-on-error-light);
 --md-sys-color-on-error-container: var(--md-sys-color-on-error-container-light);
 --md-sys-color-background: var(--md-sys-color-background-light);
 --md-sys-color-on-background: var(--md-sys-color-on-background-light);
 --md-sys-color-surface: var(--md-sys-color-surface-light);
 --md-sys-color-on-surface: var(--md-sys-color-on-surface-light);
 --md-sys-color-surface-variant: var(--md-sys-color-surface-variant-light);
 --md-sys-color-on-surface-variant: var(--md-sys-color-on-surface-variant-light);
 --md-sys-color-outline: var(--md-sys-color-outline-light);
 --md-sys-color-inverse-on-surface: var(--md-sys-color-inverse-on-surface-light);
 --md-sys-color-inverse-surface: var(--md-sys-color-inverse-surface-light);
 --md-sys-color-inverse-primary: var(--md-sys-color-inverse-primary-light);
 --md-sys-color-shadow: var(--md-sys-color-shadow-light);
 --md-sys-color-surface-tint: var(--md-sys-color-surface-tint-light);
 --md-sys-color-surface-tint-color: var(--md-sys-color-surface-tint-color-light);
 --md-sys-color-primary-elevate-1: #006e180D;
 --md-sys-color-primary-elevate-2: #006e1814;
 --md-sys-color-primary-elevate-3: #006e181C;
 --md-sys-color-primary-elevate-4: #006e181F;
 --md-sys-color-primary-elevate-5: #006e1824;
}
