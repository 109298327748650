.appWrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  background: var(--md-sys-color-primary-elevate-1);
  color: var(--md-sys-color-on-background);
}
.content {
  width: 100%;
  border-radius: 42px 0 0 42px;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.07), 0px 1px 3px rgba(0, 0, 0, 0.15);
  background: var(--md-sys-color-background);
}