.home-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .home-wrapper h1{
    margin-bottom: 0;
  }
  .home-wrapper img{
    width: 256px;
    border-radius: 16px;
    margin-bottom: 30px;
  }