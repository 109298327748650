.fab {
    background: var(--md-sys-color-primary-elevate-3);
    color: var(--md-sys-color-primary);
    width: 56px;
    height: 56px;
    border-radius: 16px;
    font-family: MontserratSemiBold;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 100ms;
    user-select: none;
    cursor: pointer;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);
}
.fab:hover {
    background: var(--md-sys-color-primary);
    color: var(--md-sys-color-on-primary);
}
.fab .material-symbols-rounded{
    font-size: 28px;
    font-weight: 500;
}